import React, { useEffect, useState } from 'react';
import qr_payment from "../images/idp_payment_QR.jpg";
import bankimg from "../images/bank-img.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown, faLock, faRightFromBracket,faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Box, CircularProgress } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from 'react-router';

export const PaymentQRpage = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);

  const [activeItem, setActiveItem] = useState("Dashboard");
  const handleClick = (item) => {
    setActiveItem(item);
  };
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const orderid = urlSearchParams.get('orderid');

    const userDataFromStorage = localStorage.getItem('userData');
    const userData = JSON.parse(userDataFromStorage);

    if (userData?.token && orderid) {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${userData.token}`);

      const formdata = new FormData();
      formdata.append("orderid", orderid);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
      };

    

      fetch("https://api.ayyawinstudyfoundation.org/api/check/paymentstatus", requestOptions)
        .then(response => response.json())  
        .then(result => {
          console.log(result.data);
          if (result.data && result.data.payment_status) {
            setPaymentStatus(result.data.payment_status);  
          }
        })
        .catch(error => console.error("Error:", error));
    }
  }, []);

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showForm1Loader, setShowForm1Loader] = useState(false);

    const userDataFromStorage = localStorage.getItem('userData');

  const userData = JSON.parse(userDataFromStorage);
    const FullScreenOverlay = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1300, // Higher than most other elements
  }));

    const handleLogout = (e) => {
    setShowModal(true);
  };
  const handleLogout2 = (e) => {
    setShowModal(false);
  };
  const handleLogout3 = (e) => {
    setShowForm1Loader(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: "", // Empty body as per your provided code
      redirect: "follow"
    };

    fetch("https://api.ayyawinstudyfoundation.org/api/admin/signout", requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result);
        // Clear user data from local storage and close the modal
        localStorage.removeItem('userData');
        setShowModal(false);
        // Optionally, you can redirect the user to the login page
        window.location.href = "/signin";
      })
      .catch(error => {
        console.error('Error:', error);
        // Optionally, display an error message to the user
      });

  };

  const [utr, setUtr] = useState(null); // State to store the selected file
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [error, setError] = useState(""); // Error message state

  const handleFileChange = (e) => {
    setUtr(e.target.value); // Capture the selected file
    setError(""); // Clear error on file change
  };

  const handleSubmit = async () => {
    if (!utr) {
      setError("Please Enter the UTR  Number before submitting."); // Show error message
      return;
    }

    setIsLoading(true); // Show loader
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    const formdata = new FormData();
    formdata.append("utr", utr); // Send the file as `utr`

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.ayyawinstudyfoundation.org/api/payment/utr/submit",
        requestOptions
      );
      const result = await response.json();
      console.log(result);
      setError(""); // Clear error on success
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // Hide loader
      setUtr('')
      navigate('/mytask');

    }
  };


  const handleDownload = async () => {
    try {
      const response = await fetch(
        "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/idp_payment_QR.jpg"
      );
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "idp_payment_QR.jpg"; // Set the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href); // Cleanup
    } catch (error) {
      console.error("Failed to download the image:", error);
    }
  };
  

  return (
    <>
      {/* <div className="container mx-auto taskpagecon mt-">
        <div>
          <div className="div">
            <a
              href="#"
              onClick={() => handleClick("Dashboard")}
              className={activeItem === "Dashboard" ? "taskpagehead" : "taskpagehead2"}
            >
              Dashboard
            </a>
            <a
              href="#"
              className={activeItem === "Tasks" ? "taskpagehead" : "taskpagehead2"}
            >
              Tasks
            </a>
            <a
              href="#"
              className={activeItem === "My account" ? "taskpagehead" : "taskpagehead2"}
            >
              My account
            </a>
             <a
              href="#"
              onClick={handleLogout}
              className="signout"
            >
              <FontAwesomeIcon icon={faRightFromBracket} style={{ color: "#fff", }} />
            </a>
          </div>
        </div>
      </div> */}

        <>
<div className="min-h-screen bg-green-100 flex items-center justify-center">
  <div className="bg-white py-2  rounded-lg shadow-lg max-w-md text-center">
    <img src={qr_payment} height="100px" alt="Success" width="80%" className="mx-auto   mb-4 fluid" style={{borderRadius:'16px'}}/>

    <div className="flex flex-col items-center ">
      <div>
      <p className="text-gray-500 text-xl font-bold pb-2">₹ 999 /-      </p>
      <p className="text-gray-500 text-sm pb-2">Scan</p>
      <div className="flex items-center justify-center w-full pb-2">
  <hr className="flex-grow border-gray-300" />
  <p className="text-gray-500 text-sm px-4">OR</p>
  <hr className="flex-grow border-gray-300" />
</div>

      <button
        onClick={handleDownload}
        className="mb-4 px-6 text-sm text-blue-800 underline"
      >
        Download QR
      </button>
      </div>
    
    </div>

    <p className="text-lg mt-2 text-gray-700 px-6 text-sm">If you have completed the payment, kindly submit the UTR number in the field provided.
</p>

<div className="flex flex-col items-center justify-center mt-10 px-5">
      <div className="flex items-center w-full max-w-lg">
        <input
          id="fileInput"
          type="input"
          multiple={false}
          value={utr}
          className="block w-full text-sm text-gray-500 px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          onChange={handleFileChange}
        />
        <button
          style={{ whiteSpace: "nowrap" }}
          onClick={handleSubmit}
          className={`px-4 py-2 text-white text-sm font-bold ${
            isLoading ? "bg-blue-400 cursor-not-allowed" : "bg-blue-700 hover:bg-blue-800"
          } rounded-r-md`}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Submit UTR"}
        </button>
      </div>
      {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
    </div>


    <button
      onClick={() => { window.location.href = "/mytask"; }}
      className="mt-6 px-6 py-3 font-semibold text-white text-sm bg-blue-500 hover:bg-blue-600 rounded-md transition duration-300"
    >
    Click here to  begin your journey with us!
    </button>
  </div>
</div>




        </>


         {showModal && (
        <div className="fixed top-0 left-0 w-full  h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white mx-5 p-8 rounded-lg shadow-lg logoutpopup">
            <h6>Log Out</h6>
             <div hidden={!showForm1Loader}>
                    {/* <div className="loader"></div> */}
                    <FullScreenOverlay>
                      <CircularProgress />
                    </FullScreenOverlay>
                  </div>
            <p>
              Are you sure you want to log out? You'll need to login again
              to use the app.
            </p>
            <div className="flex justify-between logoutbtns">
              <button onClick={handleLogout2}> Cancel</button>
              <button onClick={handleLogout3}>Log Out</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import img1 from "../images/ima (2).png";
import lock from "../images/lock.png";
import { Task } from "./Task";
import { Myaccount } from "./Myaccount";
import { Footermain } from "./Footermain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faLock,
  faRightFromBracket,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import bankimg from "../images/bank-img.png";
import wrighttick from "../images/Red-cross.png";
import badge from "../images/medal.png";
import badge1 from "../images/medal (1).png";
import badge2 from "../images/medal (2).png";
import blackbadge from "../images/blackbadge.png";
import ministar from "../images/Star Filled.png";
import star from "../images/Start.png";
import trophy from "../images/trophy-star.png";
import runn from "../images/run.png";
import { AK } from "../constants/AppKeys";
import axios, { Axios } from "axios";
import { validateToken } from "../utilities/sessionexpiry";
import { PaymentsuccessPage } from "./Paymentsuccess";
import { Box, CircularProgress } from "@mui/material";
import styled from "@emotion/styled";
import logo from "../images/logod.jpeg";
import father from "../images/father.png";
import mother from "../images/mother.png";
import kids from "../images/kids.png";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router";

export const Dashboard = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const userDataFromStorage = localStorage.getItem("userData");

  const userData = JSON.parse(userDataFromStorage);

  useEffect(() => {
    if (!userData) {
      navigate('/signin'); 
    }
  }, [navigate]);

  const [showForm1Loader, setShowForm1Loader] = useState(false);

  const [drawingshowForm1Loader, setDrawingShowForm1Loader] = useState(false);

  const [drawingsuccessshowform, setdrawingsuccessshowform] = useState(false);

  const FullScreenOverlay = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1300, // Higher than most other elements
  }));

  const handleLogout = (e) => {
    setShowModal(true);
  };
  const handleLogout2 = (e) => {
    setShowModal(false);
  };
  const handleLogout3 = (e) => {
    setShowForm1Loader(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: "", // Empty body as per your provided code
      redirect: "follow",
    };

    fetch(
      "https://api.ayyawinstudyfoundation.org/api/admin/signout",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        // Clear user data from local storage and close the modal
        localStorage.removeItem("userData");
        setShowModal(false);
        // Optionally, you can redirect the user to the login page
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error:", error);
        // Optionally, display an error message to the user
      });
  };
  const [selectedDays, setSelectedDays] = useState([]);
  const [activeItem, setActiveItem] = useState("Dashboard");

  const handleClick = (item) => {
    setActiveItem(item);
  };

  const [showContent, setShowContent] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const toggleContent = () => {
    setShowContent(!showContent);
    setShowButton(false);
  };

  const toggleButton = () => {
    setShowContent(false);
    setShowButton(true);
  };



  const payment = userData?.payment;
  const paymentQRPage = async (e) => {
    navigate('/paymentqrpage');
  };
  const payamount = async (e) => {
    setShowForm1Loader(true);
    try {
      var amount = 1500;
      let payload = {
        amount: amount,
      };

      const headers = {
        Authorization: `Bearer ${userData?.token}`,
        "Content-Type": "application/json",
      };

      return await axios
        .post(AK.APIURL + AK.HAODAPAY, payload, {
          headers,
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        })
        .then(async (response) => {
          if (
            response != typeof undefined &&
            response.data != typeof undefined
          ) {
            if (response?.data?.data?.payment_link != "") {
              window.location.href = response?.data?.data?.payment_link;
            }
          }
          return true;
        });
    } catch (error) {
      console.error(error);
      setShowForm1Loader(false);
    }
    setShowForm1Loader(false);
    return false;
  };

  useEffect(() => {
    const myHeaders = {
      "Accept": "application/json",
      "Authorization": `Bearer ${userData?.token}`,
    };

    const formData = new FormData();
    formData.append("orderid", "123");

    axios.post(AK.APIURL + AK.FIRSTTASKSTATUS, { headers: myHeaders })
    .then((response) => {
      console.log(response)
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, []); 
  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    // const urlencoded = new URLSearchParams();

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      // body: urlencoded,
      redirect: "follow"
    };

    fetch("https://api.ayyawinstudyfoundation.org/api/paid/competition/verify/status", requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Convert the response to JSON
    })
    .then((data) => {
      console.log(data); // Log the JSON result

      // Access the task_status from the result
      const result = data.task_status;

      // Set the state with the task_status value
      setdrawingsuccessshowform(result);
    })
    .catch((error) => console.error('Error:', error));
}, []); // Empty dependency array ensures this runs once on mount


  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [institute, setInstitute] = useState("");
  const [university, setUniversity] = useState("");
  const [education, setEducation] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [verified, setVerified] = useState(null);
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [imageCaption, setImageCaption] = useState("");

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
    if (event.target.files.length > 0) {
      setImage(event.target.files[0]);
    } else {
      setImage(null);
    }
  };

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    const formdata = new FormData();
    formdata.append("orderid", "123");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.ayyawinstudyfoundation.org/api/myaccount",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result?.data", result?.data); // Log the data to inspect it
        const data = result.data;
        setId(data.id);
        setName(data.name);
        setEmail(data.email);
        setMobile(data.mobile);
        setAddress1(data.address1);
        setAddress2(data.address2);
        setCity(data.city);
        setDistrict(data.district);
        setState(data.state);
        setInstitute(data.institute);
        setUniversity(data.university);
        setEducation(data.education);
        setPaymentStatus(data.payment_status);
        setVerified(data.verified);
        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  console.log(paymentStatus);
  console.log(id);
  console.log(name);

  const [tasklist, setTasklist] = useState([]);
  const [taskday, setTaskday] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userData?.token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          "https://api.ayyawinstudyfoundation.org/api/tasks",
          requestOptions
        );
        const data = await response.json(); // Parse response as JSON
        console.log("Fetched data:", data); // Log fetched data
        setTasklist(data.data.tasks);
        const tasks = data.data.tasks;

        let firstEnabledTask = null;
        for (let i = 0; i < tasks.length; i++) {
          if (tasks[i].disabled === false) {
            firstEnabledTask = tasks[i];
            break; // Stop loop once the first enabled task is found
          }
        }

        if (firstEnabledTask) {
          console.log("Last disabled task ID:", firstEnabledTask.id);
          setTaskday(firstEnabledTask.id);
          // setTaskday(100)
        } else {
          console.log("No disabled tasks found.");
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    console.log("tasklist655656", tasklist);
    console.log("taskday", taskday);
    fetchData();
  }, []);

  const [task, setTask] = useState({});
  const [taskStatus, setTaskStatus] = useState(0);
  const [userTaskDetails, setUserTaskDetails] = useState(null);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    const formdata = new FormData();

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://api.ayyawinstudyfoundation.org/api/task/${taskday}`,
      requestOptions
    )
      .then((response) => response.json()) // parse the response as JSON
      .then((result) => {
        const data = result.data;
        setTask(data.task);
        setTaskStatus(data.task_status);
        setUserTaskDetails(data.user_task_details);
        console.log("result", data); // log the variable name and its content
        console.log("task.id", data.task.id); // log the task ID
      })
      .catch((error) => console.error(error));
  }, [taskday]);

  console.log("task", task);

  let mediaContent = null;

  if (task.media_gallery) {
    try {
      // Parse the media_gallery JSON string to extract the URLs
      const mediaGallery = JSON.parse(task.media_gallery);

      // Check if mediaGallery is an array and has items
      if (Array.isArray(mediaGallery) && mediaGallery.length > 0) {
        // For simplicity, take the first item here
        const firstItemUrl = mediaGallery[0]; // This is a string containing the URL

        // Determine the type of media based on file extension
        const fileExtension = firstItemUrl.split(".").pop().toLowerCase();

        if (fileExtension === "mp4" || fileExtension === "webm") {
          // Video file extensions (adjust/add more if needed)
          mediaContent = (
            <video controls>
              <source
                src={`https://api.ayyawinstudyfoundation.org/${firstItemUrl}`}
                type={`video/${fileExtension}`}
              />
              Your browser does not support the video tag.
            </video>
          );
        } else {
          // Assume it's an image if not a recognized video extension
          mediaContent = (
            <img
              src={`https://api.ayyawinstudyfoundation.org/${firstItemUrl}`}
              alt="Task Image"
            />
          );
        }
      }
    } catch (error) {
      console.error("Error parsing media_gallery JSON:", error);
    }
  }

  const completionPercentage = (Math.min(taskday, 100) / 100) * 100;
  let backgroundColor = "#D3D3D3"; // Default color gray

  if (completionPercentage <= 25) {
    backgroundColor = "#00BFFF"; // Blue color for up to 25%
  } else if (completionPercentage <= 50) {
    backgroundColor = "blue"; // Lighter blue color for up to 50%
  } else if (completionPercentage <= 75) {
    backgroundColor = "#FF6347"; // Red color for up to 75%
  } else if (completionPercentage <= 99) {
    backgroundColor = "#8A2BE2"; // Violet color for up to 100%
  } else if (completionPercentage == 100) {
    backgroundColor = "green"; // Violet color for up to 100%
  }

  const [visible, setVisible] = useState(true);
  const messageRef = useRef(null);

  useEffect(() => {
    // Start the fade-out effect after 4 seconds
    const fadeOutTimer = setTimeout(() => {
      if (messageRef.current) {
        messageRef.current.classList.add("fade-out");
      }
    }, 4000);

    // Hide the message completely after 5 seconds
    const hideTimer = setTimeout(() => {
      setVisible(false);
    }, 5000);

    // Cleanup timers on component unmount
    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(hideTimer);
    };
  }, []);
  const iconStyle = {
    left: `calc(${completionPercentage}% - 25px)`, // Adjust `- 10px` to center the icon properly
  };
  const [validationErrors, setValidationErrors] = useState({});
  const validatedrawingForm = () => {
    let errors = {};
    let formIsValid = true;

    // Validate image
    if (!image) {
      errors.image = "Please select a valid image file.";
      formIsValid = false;
    } else {
      const maxSize = 3 * 1024 * 1024; // 5 MB in bytes
      if (image.size > maxSize) {
        errors.image = "Image size should not exceed 3 MB.";
        formIsValid = false;
      }
    }
    // console.log("image.size");
    // console.log(image.size);
    if (!imageCaption) {
      errors.imagecaption = "Please enter a caption.";
      formIsValid = false;
    }

    if (imageCaption.length > 600) {
      errors.imagecaption =
        "The caption field must not be greater than 600 characters.";
      formIsValid = false;
    }

    // // Validate received date format
    // const dateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    // if (!reciveddate.match(dateRegex)) {
    //   errors.received_date =
    //     "Please enter a valid date and time in the format YYYY-MM-DD HH:mm:ss (e.g., 2024-06-25 14:30:00).";
    //   formIsValid = false;
    // }

    setValidationErrors(errors);

    return formIsValid;
  };

  const fileInputRef = useRef(null);

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const canvasRef = useRef(null);
  const drawingSubmit = async () => {
    if (!validatedrawingForm()) {
      return;
    }
    setDrawingShowForm1Loader(true);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const productImg = new Image();
    const designImg = new Image();

    productImg.crossOrigin = "anonymous"; // to avoid CORS issues
    try {
      productImg.src = URL.createObjectURL(image);
    } catch (error) {
      console.error("Failed to create object URL for product image", error);
    }

    productImg.onload = () => {
      // canvas.width = productImg.width;
      // canvas.height = productImg.height;
      canvas.width = productImg.width;
      canvas.height = productImg.height;
      ctx.drawImage(productImg, 0, 0);

      try {
        designImg.src = logo;
      } catch (error) {
        console.error("Failed to create object URL for design image", error);
      }

      designImg.onload = () => {
        const uploaddesignImageLeft = 35;
        const uploaddesignImageTop = 55;
        const uploaddesignImageWidth = 30;
        const uploaddesignImageHeight = 10;
        const opacity = 0.1; // Set the desired opacity

        // Set global alpha to apply opacity
        ctx.globalAlpha = opacity;
        ctx.drawImage(
          designImg,
          (uploaddesignImageLeft / 100) * canvas.width,
          (uploaddesignImageTop / 100) * canvas.height,
          (uploaddesignImageWidth / 100) * canvas.width,
          (uploaddesignImageHeight / 100) * canvas.height
        );

        // ctx.drawImage(
        //   designImg,
        //   uploaddesignImageLeft,
        //   uploaddesignImageTop,
        //   uploaddesignImageWidth,
        //   uploaddesignImageHeight
        // );

        // Reset global alpha to default value
        ctx.globalAlpha = 1.0;

        canvas.toBlob(async (blob) => {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          try {
            const compressedFile = await imageCompression(blob, options);
            console.log(
              "compressedFile instanceof Blob",
              compressedFile instanceof Blob
            ); // true
            console.log(
              `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
            ); // smaller than maxSizeMB

            // const link = document.createElement("a");
            // link.download = `image.png`;
            // link.href = compressedFile.toDataURL();
            // link.click();

            // const link = document.createElement("a");
            // link.download = `image.png`;
            // const url = URL.createObjectURL(compressedFile);
            // link.href = url;
            // link.click();
            // URL.revokeObjectURL(url);

            const formdata = new FormData();
            formdata.append("image", compressedFile); // Append the canvas Blob
            formdata.append("caption", imageCaption);

            // Headers
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${userData?.token}`);

            const requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow",
            };

            fetch(
              "https://api.ayyawinstudyfoundation.org/api/competition/submit/request",
              requestOptions
            )
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                return response.json(); // assuming response is JSON
              })
              .then((data) => {
                // Check for success based on your API response structure
                if (data && data.success) {
                  setdrawingsuccessshowform(true);
                  setImage(null);
                  setFiles([]);
                  setImageCaption("");
                  clearFileInput();
                  // setTimeout(() => {
                  //   setdrawingsuccessshowform(false);
                  // }, 2000);

                  // Cleanup timers on component unmount

                  // setShowform(true);
                  console.log(data); // Handle successful response
                } else {
                  // setShowform(false);
                  console.error("Submission failed:", data); // Log error or handle as needed
                }
              })
              .catch((error) => {
                console.error("Fetch error:", error); // Handle fetch errors
              })
              .finally(() => {
               setDrawingShowForm1Loader(false); // Hide loader regardless of success or failure
              });
          } catch (error) {
            console.log(error);
          }
        }, "image/png");
        // const link = document.createElement("a");
        // link.download = `download.png`;
        // link.href = canvas.toDataURL();
        // link.click();
      };

      designImg.onerror = (error) => {
        console.error("Failed to load design image", error);
      };
    };

    productImg.onerror = (error) => {
      console.error("Failed to load product image", error);
    };

    // const link = document.createElement("a");
    // link.download = `test.png`;
    // link.href = canvas.toDataURL();
    // link.click();
    // Format received date to match Y-m-d H:i:s

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userData?.token}`);

    const formdata = new FormData();
    formdata.append("image", productImg);
    formdata.append("caption", imageCaption);

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://api.ayyawinstudyfoundation.org/api/competition/submit/request",
    //   requestOptions
    // )
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     return response.json(); // assuming response is JSON
    //   })
    //   .then((data) => {
    //     // Check for success based on your API response structure
    //     if (data && data.success) {
    //       setdrawingsuccessshowform(true);
    //       setImage(null);
    //       setFiles([]);
    //       setImageCaption("");
    //       setTimeout(() => {
    //         setdrawingsuccessshowform(false);
    //       }, 2000);

    //       // Cleanup timers on component unmount

    //       // setShowform(true);
    //       console.log(data); // Handle successful response
    //     } else {
    //       // setShowform(false);
    //       console.error("Submission failed:", data); // Log error or handle as needed
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Fetch error:", error); // Handle fetch errors
    //   })
    //   .finally(() => {
    //     setDrawingShowForm1Loader(false); // Hide loader regardless of success or failure
    //   });
  };

  return (
    <>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      <div className="container mx-auto taskpagecon mt-">
        <div>
          <div className="div">
            <a
              href="#"
              onClick={() => handleClick("Dashboard")}
              className={
                activeItem === "Dashboard" ? "taskpagehead" : "taskpagehead2"
              }
            >
              Dashboard
            </a>
            <a
              style={{ color: activeItem === "Tasks" ? "#fff" : "#828282" }}
              href="#"
              onClick={
                paymentStatus === "SUCCESS"
                  ? () => handleClick("Tasks")
                  : undefined
              }
              className={
                paymentStatus === "SUCCESS"
                  ? activeItem === "Tasks"
                    ? "taskpagehead"
                    : "taskpagehead2"
                  : ""
              }
            >
              Tasks
            </a>
            <a
              href="#"
              onClick={() => handleClick("My account")}
              className={
                activeItem === "My account" ? "taskpagehead" : "taskpagehead2"
              }
            >
              My account
            </a>
            <a href="#" onClick={handleLogout} className="signout">
              <FontAwesomeIcon
                icon={faRightFromBracket}
                style={{ color: "#fff" }}
              />
            </a>
          </div>
        </div>
      </div>

      
      {activeItem == "Dashboard" && (
        <>
          {paymentStatus === "PENDING" &&
            paymentStatusPending(payamount, showForm1Loader, FullScreenOverlay,paymentQRPage)}
          {paymentStatus === "PROCESSING" && paymentStatusProcessing()}
          {paymentStatus === "SUCCESS" && (
            <> 
{drawingsuccessshowform ? (
        <>
   {PaidcompetitionSuccess(
                mediaContent,
                visible,
                messageRef,
                userData,
                taskday,
                task,
                completionPercentage,
                backgroundColor,
                iconStyle,
                tasklist,
                selectedDays,
                handleClick,
                handleFileChange,
                image,
                drawingSubmit,
                validationErrors,
                imageCaption,
                setImageCaption,
                fileInputRef
              )}

        </>
      ):( drawingcompetitionSuccess(
        mediaContent,
        visible,
        messageRef,
        userData,
        taskday,
        task,
        completionPercentage,
        backgroundColor,
        iconStyle,
        tasklist,
        selectedDays,
        handleClick,
        handleFileChange,
        image,
        drawingSubmit,
        validationErrors,
        imageCaption,
        setImageCaption,
        fileInputRef
      ))}

             
           
               {familycompetitionSuccess(
                mediaContent,
                visible,
                messageRef,
                userData,
                taskday,
                task,
                completionPercentage,
                backgroundColor,
                iconStyle,
                tasklist,
                selectedDays,
                handleClick,
                handleFileChange,
                image,
                drawingSubmit,
                validationErrors,
                imageCaption,
                setImageCaption,
                fileInputRef
              )}
              {paymentStatusSuccess(
                mediaContent,
                visible,
                messageRef,
                userData,
                taskday,
                task,
                completionPercentage,
                backgroundColor,
                iconStyle,
                tasklist,
                selectedDays,
                handleClick
              )}
            </>
          )}
        </>
      )}
      {showModal && (
        <div className="fixed top-0 left-0 w-full  h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white mx-5 p-8 rounded-lg shadow-lg logoutpopup">
            <h6>Log Out</h6>
            <div hidden={!showForm1Loader}>
              {/* <div className="loader"></div> */}
              <FullScreenOverlay>
                <CircularProgress />
              </FullScreenOverlay>
            </div>
            <p>
              Are you sure you want to log out? You'll need to login again to
              use the app.
            </p>
            <div className="flex justify-between logoutbtns">
              <button onClick={handleLogout2}> Cancel</button>
              <button onClick={handleLogout3}>Log Out</button>
            </div>
          </div>
        </div>
      )}
      {/* paymentStatus === "FAILED" && */}
      {paymentStatus === "FAILED" && (
        <>
          {" "}
          <div className="succestick container mx-auto mt-16 pt-16 p-4">
            <img src={wrighttick} width={"200px"} />
            <h2 className="text-4xl text-center font-bold text-black mb-8"></h2>
            <center>
              {" "}
              <p className="font-bold text-red-900">
                Oops! It seems there was an issue completing your payment.
                Please try agin after sometime.
              </p>
            </center>
            {/* <a className="inline-block px-6 py-3 font-bold mt-16 text-lg transition duration-300 ease-in-out transform border border-transparent rounded-md shadow-md text-white bg-red-500 hover:bg-red-600 hover:shadow-lg" href="/mytask">Back to Home</a> */}
          </div>
        </>
      )}
      {activeItem === "Tasks" ? <Task /> : <></>}

      {activeItem === "My account" ? <Myaccount /> : <></>}

      <div hidden={!drawingshowForm1Loader}>
        <div className="loader"></div>
        <FullScreenOverlay>
          <CircularProgress />
        </FullScreenOverlay>
      </div>

      {/* <PaymentsuccessPage/> */}
    </>
  );
};

function drawingcompetitionSuccess(
  mediaContent,
  visible,
  messageRef,
  userData,
  taskday,
  task,
  completionPercentage,
  backgroundColor,
  iconStyle,
  tasklist,
  selectedDays,
  handleClick,
  handleFileChange,
  image,
  drawingSubmit,
  validationErrors,
  imageCaption,
  setImageCaption,
  fileInputRef
) {
  return (
    <>
      <div className="block md:flex items-center justify-center">
        <div className="w-full md:w-2/6 mt-5 flex justify-center p-5 container mx-auto">
          <img
            src={
              image === null
                ? `https://static.mygov.in/indiancc/2021/06/mygov-10000000001443244387-807x1024.jpg`
                : URL.createObjectURL(image)
            }
            alt="Task Image"
          />
          <img
            data-v-48d09600=""
            className="absolute"
            src={logo}
            alt=""
            style={{
              width: `10%`,
              top: `55%`,
              left: `15%`,
              opacity: `0.1`,
              transform: "rotate(0deg) skew(0deg, 0deg)",
            }}
          />
        </div>
        <div className="w-full md:w-3/5 p-0 md:p-10">
          <div className="taskcontent  p-5 container mx-auto">
            {visible ? (
              <>

                    <h3 
                  ref={messageRef}
                  className="text-xl font-bold mb-3 text-green-800 bg-green-300 p-4 rounded-lg shadow-lg animate-slide-in"
                >
                  🎉 Welcome, {userData?.name} ! 🌟 Enjoy your journey with
                  Ayyawin Foundation 🍃
                </h3>
              </>
            ) : (
              <>
                <h3
                  style={{ width: "fit-content" }}
                  className="text-xl font-bold text-green-800  p-4 rounded-lg  "
                >
                  <span className="uppercase flex items-center">
                    {taskday < 26 ? (
                      <></>
                    ) : (
                      <img
                        width={"25px"}
                        className="badgerotate"
                        src={
                          taskday === 100
                            ? trophy
                            : taskday > 75
                            ? badge2
                            : taskday > 50
                            ? badge1
                            : taskday > 25
                            ? blackbadge
                            : blackbadge // Fallback image
                        }
                      />
                    )}
                    {taskday < 26 && <> 🎉</>} Welcome, {userData?.name} !
                  </span>
                  <br />
  

                </h3>
              </>
            )}
                <span className="uppercase flex items-center font-bold">
                    DRAWING COMPETITION ! <br />
                    <br />
                  </span>

                  <span className="text-gray-700 text-sm">
      The "Uniqueness Colour" project is an ambitious initiative aimed at setting an India World Record by engaging schools and families in a series of enriching competitions and activities over three months. This project is designed to promote creativity, family bonding, healthy living, and educational values.
    </span>
            <div className="cmtsectioninputs">
              <label htmlFor="fileInput" className="mt-2 block w-full">
                <input
                  multiple
                  id="fileInput"
                  onChange={handleFileChange}
                  type="file"
                  ref={fileInputRef}
                  className="mb-4 block w-full text-sm mt-5 text-gray-500
                   file:mr-4 file:py-2 file:px-4
                   file:rounded-full file:border-0
                   file:text-sm file:font-semibold
                   file:bg-green-200 file:text-green-900
                   hover:file:bg-green-100"
                />
              </label>

              {validationErrors.image && (
                <p
                  className="text-red-500 text-xs mt-1"
                  style={{ color: "red" }}
                >
                  {validationErrors.image}
                </p>
              )}

              <textarea
                onChange={(event) => setImageCaption(event.target.value)}
                value={imageCaption}
                placeholder="Enter your caption"
               className="w-full px-4 py-2 border rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-green-300 transition-all duration-300"
        rows="4"
              ></textarea>

              {validationErrors.imagecaption && (
                <p
                  className="text-red-500 text-xs mt-1"
                  style={{ color: "red" }}
                >
                  {validationErrors.imagecaption}
                </p>
              )}

              <div className="flex items-center mt-5">
                <label
                  htmlFor="green-checkbox"
                  className="ml-2 text-sm font-medium text-gray-500"
                >
                  Thank you for submitting your details! Please click the button
                  below to confirm your submission.
                </label>
              </div>

              <button
                onClick={(event) => {
                  event.preventDefault();
                  drawingSubmit();
                }}
                className="w-full mt-3 px-4 py-2 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300 transition-all duration-300"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function PaidcompetitionSuccess(
  mediaContent,
  visible,
  messageRef,
  userData,
  taskday,
  task,
  completionPercentage,
  backgroundColor,
  iconStyle,
  tasklist,
  selectedDays,
  handleClick,
  handleFileChange,
  image,
  drawingSubmit,
  validationErrors,
  imageCaption,
  setImageCaption,
  fileInputRef
) {
  return (
    <>
      <div   className="container flex justify-center mx-auto">
        <div className="rounded-lg msgtwolevel shadow-lg pb-5">
          <center>
      <svg
                            className="checkmark h-12 w-12 text-green-500 animate-checkmark"
                            viewBox="0 0 52 52"
                          >
                            <circle
                              className="checkmark-circle"
                              cx="26" 
                              cy="26"
                              r="23"
                              fill="none"
                            />
                            <path
                              className="checkmark-check"
                              fill="none"
                              d="M14 27l7 7 16-16"
                            />
                          </svg>
      <style jsx>{`
                      @keyframes stroke {
                        0% {
                          stroke-dasharray: 0, 100;
                        }
                        100% {
                          stroke-dasharray: 100, 0;
                        }
                      }

                      @keyframes scale {
                        0%,
                        100% {
                          transform: none;
                        }
                        50% {
                          transform: scale3d(1.1, 1.1, 1.1);
                        }
                      }

                      .checkmark-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }

                      .checkmark-circle {
                        stroke: #34d399;
                        stroke-width: 4;
                      }

                      .checkmark-check {
                        stroke: #34d399;
                        stroke-width: 4;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 100;
                        stroke-dashoffset: 0;
                        animation: stroke 0.5s cubic-bezier(0.65, 0, 0.45, 1)
                            forwards,
                          scale 0.3s ease-in-out 0.5s forwards;
                      }
                    `}</style>
                    </center>
                    <h3

  className="text-xl font-bold text-green-800 p-4 "
>
  🎉 {userData?.name}! You have successfully completed the First Level Task. 
  🌟 Continue to the Second Level Task &gt;&gt; <a className=" text-blue-800 " href="/familytask" target="_blank">Continue </a> <br/>
 
</h3>
<p className=" text-gray-500 my-1  p-3">
  Please note : The Second Level is a paid version, and the amount paid will support our organization, not for personal use.
</p>


      </div>
      </div>
    </>
  );
}
function familycompetitionSuccess(
  mediaContent,
  visible,
  messageRef,
  userData,
  taskday,
  task,
  completionPercentage,
  backgroundColor,
  iconStyle,
  tasklist,
  selectedDays,
  handleClick,
  handleFileChange,
  image,
  drawingSubmit,
  validationErrors,
  imageCaption,
  setImageCaption,
  fileInputRef
) {
  return (
    <>
      <div className="block md:flex items-center justify-center">

        <div className="w-full p-0 md:p-10">
          <div className="taskcontent  p-5 container mx-auto">
        

            {/* <div className="cmtsectioninputs">
              <label htmlFor="fileInput" className="mt-2 block w-full">
                <input
                  multiple
                  id="fileInput"
                  onChange={handleFileChange}
                  type="file"
                  ref={fileInputRef}
                  className="mb-4 block w-full text-sm mt-5 text-gray-500
                   file:mr-4 file:py-2 file:px-4
                   file:rounded-full file:border-0
                   file:text-sm file:font-semibold
                   file:bg-violet-50 file:text-violet-700
                   hover:file:bg-violet-100"
                />
              </label>

              {validationErrors.image && (
                <p
                  className="text-red-500 text-xs mt-1"
                  style={{ color: "red" }}
                >
                  {validationErrors.image}
                </p>
              )}

              <textarea
                onChange={(event) => setImageCaption(event.target.value)}
                value={imageCaption}
                placeholder="Enter your caption"
                className="w-full h-32 p-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white text-gray-700"
              ></textarea>

              {validationErrors.imagecaption && (
                <p
                  className="text-red-500 text-xs mt-1"
                  style={{ color: "red" }}
                >
                  {validationErrors.imagecaption}
                </p>
              )}

              <div className="flex items-center mt-5">
                <label
                  htmlFor="green-checkbox"
                  className="ml-2 text-sm font-medium text-gray-500"
                >
                  Thank you for submitting your details! Please click the button
                  below to confirm your submission.
                </label>
              </div>

              <button
                onClick={(event) => {
                  event.preventDefault();
                  drawingSubmit();
                }}
                className="bg-green-600 mt-6 rounded-md hover:bg-green-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"
              >
                Submit
              </button>
            </div> */}
    


          </div>
        </div>
      </div>
    </>
  );
}
function paymentStatusSuccess(
  mediaContent,
  visible,
  messageRef,
  userData,
  taskday,
  task,
  completionPercentage,
  backgroundColor,
  iconStyle,
  tasklist,
  selectedDays,
  handleClick
) {
  return (
    <>
      <div className="block md:flex items-center justify-center">
        <div className="w-full md:w-2/6 mt-5 flex justify-center p-5 container mx-auto">
          {mediaContent}
        </div>
        <div className="w-full md:w-3/5 p-0 md:p-10">
          <div className="taskcontent  p-5 container mx-auto">
            {visible ? (
              <h3
                ref={messageRef}
                className="text-xl font-bold text-green-800 bg-green-300 p-4 rounded-lg shadow-lg animate-slide-in"
              >
                🎉 Welcome, {userData?.name}! 🌟 Enjoy your journey with Ayyawin
                Foundation 🍃
              </h3>
            ) : (
              <>
                <h3
                  style={{ width: "fit-content" }}
                  className="text-xl font-bold text-green-800  p-4 rounded-lg  "
                >
                  <span className="uppercase flex items-center">
                    {taskday < 26 ? (
                      <></>
                    ) : (
                      <img
                        width={"25px"}
                        className="badgerotate"
                        src={
                          taskday === 100
                            ? trophy
                            : taskday > 75
                            ? badge2
                            : taskday > 50
                            ? badge1
                            : taskday > 25
                            ? blackbadge
                            : blackbadge // Fallback image
                        }
                      />
                    )}
                    {taskday < 26 && <> 🎉</>} Welcome, {userData?.name} !
                  </span>
                </h3>
              
              </>
            )}
              <p className=" text-center py-3 font-bold Text-green-800 ">
                  Completed Task :
                  <span
                    className={`px-2 ${
                      taskday === 100
                        ? "text-green-800"
                        : taskday >= 76
                        ? "text-indigo-900"
                        : taskday >= 51
                        ? "text-red-800"
                        : taskday >= 26
                        ? "text-blue-800"
                        : "text-black"
                    }`}
                  >
                    {taskday - 1}
                  </span>
                </p>

                <div className="medals">
                  <span
                    className={`${
                      taskday >= 26
                        ? "text-blue-800 bg-blue-400"
                        : "text-gray-800 bg-gray-300"
                    } shadow`}
                  >
                    <img width={"20px"} src={badge} />
                    25-Seed
                  </span>
                  <span
                    className={`${
                      taskday >= 51
                        ? "text-red-800 bg-red-400"
                        : "text-gray-800 bg-gray-300"
                    } shadow`}
                  >
                    <img width={"20px"} src={badge1} />
                    50-Grow
                  </span>
                  <span
                    className={`${
                      taskday >= 76
                        ? "text-indigo-800 bg-indigo-400"
                        : "text-gray-800 bg-gray-300"
                    } shadow`}
                  >
                    <img width={"20px"} src={badge2} />
                    75-Bloom
                  </span>
                  <span
                    className={`${
                      taskday == 100
                        ? "text-green-800 bg-green-400"
                        : "text-gray-800 bg-gray-300"
                    } shadow`}
                  >
                    <img width={"20px"} src={trophy} />
                    100-Yield
                  </span>
                </div>
            <h2 className="uppercase">{task.title}</h2>
            <p>{task.description}</p>
          </div>
          <div className="taskcontent2 p-5 container mx-auto">
            <div className="flex justify-between mb-5 ">
              <p>Day</p>
              <p>{taskday}</p>
            </div>
            <div className="trophyimag" style={{ width: "100%" }}>
              <img style={{ width: "30px" }} src={trophy}></img>
            </div>
            <div className="linebox">
              <div
                className="linebox2"
                style={{
                  width: `${completionPercentage}%`,
                  backgroundColor,
                }}
              ></div>
              <img
                src={runn}
                className="person-icon runnimg"
                style={iconStyle}
                alt="Person Icon"
              />
            </div>
          </div>

          <div className="daysection p-5 container mx-auto">
            <h6>Start your day</h6>
            <div>
              {Array.isArray(tasklist) && tasklist.length > 0 ? (
                tasklist
                  .slice()
                  .reverse() // Reverse the list
                  .map((task, index) => (
                    <span
                      key={index}
                      className={`flex items-center shadow  ${
                        selectedDays.includes(index) || task.disabled
                          ? "selectedday"
                          : "unselectedday"
                      } ${
                        task.task_status === 1
                          ? "completedday"
                          : "unselectedday"
                      }`}
                    >
                      <b>
                        DAY {task.day} &nbsp;&nbsp;{" "}
                        {task.task_status === 1 ? (
                          <FontAwesomeIcon icon={faCircleCheck} />
                        ) : task.disabled ? (
                          <FontAwesomeIcon icon={faLock} />
                        ) : null}
                      </b>
                    </span>
                  ))
              ) : (
                <div>No tasks available</div>
              )}
            </div>
          </div>

          <div className="p-5 container mx-auto">
            {selectedDays.length === 0 ? (
              <button
                onClick={() => {
                  handleClick("Tasks");
                }}
                className="bg-blue-600 mt-6 input-button hover:bg-blue-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"
              >
                Get Start your Task
              </button>
            ) : (
              <button
                onClick={() => handleClick("Tasks")}
                className="bg-blue-600 mt-6 input-button hover:bg-blue-700 text-white font-bold py-4 px-8 w-full mb-8 focus:outline-none focus:shadow-outline"
              >
                Continue Task
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function paymentStatusProcessing() {
  return (
    <>
      <div className="block md:flex items-center justify-center">
        <div className="w-full md:w-2/6 mt-5 flex justify-center p-5 container mx-auto">
          <img src={bankimg} alt="" />
        </div>
        <div className="w-full md:w-3/5 p-0 md:p-10">
          <div className="taskcontent text-center p-5 container mx-auto rounded ">
            <h3 className="text-2xl font-semibold mb-4">Processing Your Request</h3>
            <p className="mb-2">Please wait for our confirmation</p>
            <p className="text-sm text-gray-600">We appreciate your patience.</p>
            <p className="text-sm text-gray-600">
              If you have any questions, feel free to contact us.
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
      </div>
    </>
  );
}

function paymentStatusPending(payamount, showForm1Loader, FullScreenOverlay,paymentQRPage) {
  return (
    <>
      <div className="p-2 md:max-w-lg container mx-auto">
        <h2 className="text-2xl font-bold text-black mb-8">
          Activate Member Ship! 💳
        </h2>
        <h2 className="text-2xl font-bold text-black mb-8">
          IDP (CO CURRICULUM ACTIVITIES)
        </h2>
        <p className="text-sm text-gray-500">
          பள்ளிகள் அறிவு சார்ந்த கல்வியை சிறப்பான முறையில் போதித்து வருகின்றனர்.
          மாணவர்களின் தனித் திறமைகளை வளர்க்கும் பொருட்டு ஒருங்கிணைந்த
          செயல்பாடுகள் என்னும் திட்டத்தின் கீழ் INTEGRATED DEVELOPMENT PROGRAM
          (IDP) அன்றாட நிகழ்வுகளை ஒழுங்கமைத்துக் கொள்ளவும், அறிவுக்கூர்மையை
          வளர்த்துக் கொள்ளவும், சமூக பங்களிப்புகளை செவ்வனே செய்யவும் பயிற்சி
          முறை திட்டமிடப்பட்டுள்ளது.
        </p>
        <br />
        <div style={{ position: "relative", padding: "10px" }}>
          <div className="paybox">
            <h2>
              <b>AYYA"WIN NALS SHOPPY</b>
              <span style={{ float: "right" }}>
                <b>₹999/-</b>
              </span>
            </h2>
            <p>
              <span>Description or discount</span>
              <span style={{ float: "right", fontSize: "12px" }}>90 Days</span>
            </p>
            <img className="staricon" src={star} alt="Star" />
          </div>
        </div>
        <br />
        <div>
          <h2>
            <b>You'll get:</b>
          </h2>
        </div>
        <div className="starbox">
          <p className="flex items-center ">
            <img className="mr-5" src={ministar} alt="" />
            தினசரி செயல்பாடுகள் குறித்த பயிற்சிக் கையேடு
          </p>
          <p className="flex items-center ">
            <img className="mr-5" src={ministar} alt="" />
            மரத்தின் விதைகள் மற்றும் நர்சரி கவர்
          </p>
          <p className="flex items-center ">
            <img className="mr-5" src={ministar} alt="" />
            ரூ.260 மதிப்புள்ள சிறுதானிய உணவுப் பொருட்கள் தொகுப்பு
          </p>
          <p className="flex items-center ">
            <img className="mr-5" src={ministar} alt="" />
            வீட்டுத் தோட்ட உபகரணங்கள் தொகுப்பு மற்றும் கையேடு
          </p>
          <p className="flex items-center ">
            <img className="mr-5" src={ministar} alt="" />
            பங்கேற்பாளர் சான்றிதழ் மற்றும் மெடல்
          </p>

          <p className="mt-5 font-bold text-center">
            வகுப்புகள் அனைத்தும் ஆன்லைன் மூலமாக நடைபெறும்.
          </p>

          <p className="font-bold text-center">
            மாவட்ட, மாநில அளவிலான திறன் மேம்பாட்டு நேரடிப் போட்டிகள் நடைபெறும்.
            இடம் மற்றும் நேரம் பின்னர் அறிவிக்கப்படும்.
          </p>
        </div>
        <button
          onClick={(e) => {
            // payamount();
            paymentQRPage();
          }}
          className="bg-blue-500 input-button mt-4 hover:bg-blue-700 text-white font-bold py-4 px-8 w-full focus:outline-none focus:shadow-outline"
        >
          <div hidden={!showForm1Loader}>
            {/* <div className="loader"></div> */}
            <FullScreenOverlay>
              <CircularProgress />
            </FullScreenOverlay>
          </div>
          Pay
        </button>
      </div>
    </>
  );
}
